.expense-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  padding: 0.5rem;
  margin: 1rem 0;
  border-radius: 12px;
  background-color: #686868;
}

.expense-item__description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
  flex-flow: column-reverse;
  justify-content: flex-start;
  flex: 1;
}

.delete-button
{
  background-color: rgb(49, 49, 49);
  font-size: 2.2rem;
  color: white;
  border: 2px solid white;
  border-radius: 50%;
}
.delete-button:hover
{
  background-color: white;
  color: black;
  cursor: pointer;
}
.expense-item h2 {
  font-size: 1rem;
  flex: 1;
  margin: 0 1rem;
  color: rgb(255, 255, 255);
  border: 2px solid white;
  width: fit-content;
  padding: 2px;
}

.expense-item__price {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #40005d;
  border: 1px solid white;
  padding: 0.5rem;
  margin-right: 1rem;
  border-radius: 12px;
  width: 4rem;
}

@media (min-width: 580px) {
  .expense-item__description {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }

  .expense-item__description h2 {
    font-size: 1.25rem;
    border: none;
  }

  .expense-item__price {
    font-size: 1.25rem;
    padding: 0.5rem 1.5rem;
  }
}