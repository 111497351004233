.expenses
{
    
    padding: 1rem;
  /* background-color: rgb(187, 187, 187); */
  margin:1rem auto;
  width: 50rem;
  max-width: 80%;
  
}

@media (min-width: 580px) {
  .expenses
  {
    width: 50vw;
  }
}